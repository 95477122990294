<template>
	<section ref="animationSection" class="section cta" :class="{'with-margin': value.cta_margin}">
		<base-container class="cta-container">
			<div class="top-wrapper">
				<div class="title">
					<cms-text :value="`${prefix}.cta_title`" :props="{variant: 'cta', tag: 'h2'}"/>
				</div>
				<cms-image
					class="mobile-photo"
					:src="{path: 'homepage/CTA/animation/mobile.png'}"
					:base="{ width: 576 }"
					:sm="{ width: 768 }"
					:md="{ width: 992 }"
					:lg="{ width: 1200 }"
				/>
			</div>
			<base-row>
				<base-col col="12" xl="6">
					<div class="cta-content-wrapper">
						<div class="cta-description">
							<cms-text :props="{tag: 'div'}" :value="`${prefix}.cta_description`" />
						</div>
						<base-button class="cta-button" :to="`${value.cta_btn_link}`" type="secondary">
							<cms-text :value="`${prefix}.cta_btn`" :props="{clear: true}"/>
						</base-button>
						<cms-text class="cta-phone-text" :props="{tag: 'div'}" :value="`${prefix}.cta_phone`" />
						<div class="cta-phone">
							<base-svg class="cta-icon" name="email"/>
							<base-link type="black" :url="`mailto:${$app.settings.general.contact.email}`" target="_self">
								<base-font class="cta-email" variant="hero-header-medium">
									{{$app.settings.general.contact.email}}
								</base-font>
							</base-link>
						</div>
					</div>
				</base-col>
			</base-row>
		</base-container>
		<div class="images-wrapper">
			<cms-image
				class="animation-photo first"
				lazy
				:src="{path: 'homepage/CTA/animation/lawenda_3.png'}"
				:base="{height: 42}"
				:xl="{height: 180}"
			/>
			<cms-image
				class="animation-photo second"
				lazy
				:src="{path: 'homepage/CTA/animation/liscie_2.png'}"
				:base="{width: 42}"
				:xl="{width: 180}"
			/>
			<cms-image
				class="animation-photo third"
				lazy
				:src="{path: 'homepage/CTA/animation/lawenda_2.png'}"
				:base="{width: 42}"
				:xl="{height: 300}"
			/>
			<cms-image
				class="animation-photo fourth"
				lazy
				:src="{path: 'homepage/CTA/animation/lawenda_2.png'}"
				:base="{width: 42}"
				:xl="{height: 300}"
			/>
			<cms-image
				class="animation-photo fifth"
				lazy
				:src="{path: 'homepage/CTA/animation/liscie_1.png'}"
				:base="{width: 44}"
				:xl="{width: 180}"
			/>
			<cms-image
				class="animation-photo sixth"
				lazy
				:src="{path: 'homepage/CTA/animation/lawenda_3.png'}"
				:base="{width: 44}"
				:xl="{width: 70}"
			/>
			<cms-image
				class="animation-photo seventh"
				lazy
				:src="{path: 'homepage/CTA/animation/rumianek_1.png'}"
				:base="{width: 44}"
				:xl="{width: 240}"
			/>
			<cms-image
				class="animation-photo eighth"
				lazy
				:src="{path: 'homepage/CTA/animation/lawenda_1.png'}"
				:base="{width: 44}"
				:xl="{width: 190}"
			/>
			<cms-image
				class="animation-photo ninth"
				lazy
				:src="{path: 'homepage/CTA/animation/liscie_2.png'}"
				:base="{width: 44}"
				:xl="{width: 190}"
			/>
			<cms-image
				class="animation-photo tenth"
				lazy
				:src="{path: 'homepage/CTA/animation/dlonie_obie.png'}"
				:base="{width: 44}"
				:xl="{width: 450}"
				:xxl="{width: 600}"
			/>
			<cms-image
				class="animation-photo eleventh"
				lazy
				:src="{path: 'homepage/CTA/animation/kropla.png'}"
				:base="{width: 44}"
				:xl="{width: 120}"
				:xxl="{width: 150}"
			/>
			<cms-image
				class="animation-photo twelfth"
				lazy
				:src="{path: 'homepage/CTA/animation/lawenda_2.png'}"
				:base="{width: 44}"
				:xl="{height: 300}"
			/>
			<cms-image
				class="animation-photo thirteenth"
				lazy
				:src="{path: 'homepage/CTA/animation/lawenda_3.png'}"
				:base="{width: 44}"
				:xl="{width: 70}"
			/>
			<cms-image
				class="animation-photo fourteenth"
				lazy
				:src="{path: 'homepage/CTA/animation/rumianek_2.png'}"
				:base="{width: 44}"
				:xl="{width: 200}"
			/>
			<cms-image
				class="animation-photo fifteenth"
				lazy
				:src="{path: 'homepage/CTA/animation/liscie_1.png'}"
				:base="{width: 44}"
				:xl="{width: 180}"
			/>
			<cms-image
				class="animation-photo sixteenth"
				lazy
				:src="{path: 'homepage/CTA/animation/rumianek_1.png'}"
				:base="{width: 44}"
				:xl="{width: 200}"
			/>
			<cms-image
				class="animation-photo seventeenth"
				lazy
				:src="{path: 'homepage/CTA/animation/rumianek_2.png'}"
				:base="{width: 44}"
				:xl="{width: 200}"
			/>
			<cms-image
				class="animation-photo eighteenth"
				lazy
				:src="{path: 'homepage/CTA/animation/liscie_2.png'}"
				:base="{width: 44}"
				:xl="{height: 400}"
			/>
		</div>
	</section>
</template>
<script>
export default {
	props: {
		prefix: String,
		value: Object
	},
	data: () => ({
		observer: false
	}),
	methods: {
		moveLeaves (entries) {
			entries.forEach(entry => {
				if (entry.isIntersecting) this.$refs.animationSection.classList.add('visible')
				else this.$refs.animationSection.classList.remove('visible')
			})
		}
	},
	computed: {
		options () {
			return {
				root: null,
				rootMargin: '0px',
				threshold: 0.6
			}
		}
	},
	mounted () {
		if (this.$app.page.route.meta.alias !== 'homepage') {
			this.observer = new IntersectionObserver(this.moveLeaves, this.options)
			this.observer.observe(this.$refs.animationSection)
		}
	}
}
</script>
<style lang="scss" scoped>
.images-wrapper {
	display: none;
	@include media-breakpoint-up(xl) {
		display: block;

		height: 100%;
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
	}

	&:after {
		content: "";
		width: 100%;
		height: 100%;
		background-color: rgba(255,255,255,0.6);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
	}
}
.mobile-photo {
	& :deep(img)  {
		width: 100%;
		display: flex;
	}
	@include media-breakpoint-up(xl) {
		display: none;
	}
	&:after {
		content: "";
		width: 100%;
		height: 100%;
		background-color: rgba(255,255,255,0.6);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
	}
}
.animation-photo {
	position: absolute;
	transition: .3s ease-in;
	&.first {
		top: 150px;
		left: -25px;
		@include media-breakpoint-up(xxl) {
			top: 150px;
			left: -25px;
		}
	}
	&.second {
		z-index: 1;
		left: -20px;
		bottom: 0;
		transform: rotate(15deg);
	}
	&.third {
		z-index: 2;
		left: 130px;
		bottom: -50px;
	}
	&.fourth {
		z-index: 1;
		left: 110px;
		bottom: -150px;
		transform: rotate(350deg);
	}
	&.fifth {
		z-index: 2;
		left: 250px;
		bottom: 100px;
	}
	&.sixth {
		left: 270px;
		bottom: -20px;
	}
	&.fifth {
		left: 600px;
		bottom: -300px;
		transform: rotate(95deg);
	}
	&.sixth {
		transform: scaleX(-1);
		right: 900px;
		bottom: -50px;
	}
	&.seventh {
		z-index: 1;
		right: 650px;
		bottom: -35px;
	}
	&.eighth {
		right: 600px;
		bottom: -150px;
	}
	&.ninth {
		right: 500px;
		bottom: -150px;
	}
	&.tenth {
		z-index: 1;
		right: -10px;
		top: -30px;
	}
	&.eleventh {
		right: 200px;
		bottom: 180px;

		@include media-breakpoint-up(xxl) {
			right: 250px;
			bottom: 200px;
		}
	}
	&.twelfth {
		right: 130px;
		bottom: 160px;
	}
	&.thirteenth {
		z-index: 1;
		right: 100px;
		bottom: -50px;
	}
	&.fourteenth {
		right: -100px;
		bottom: -100px;
	}
	&.fifteenth {
		right: -10px;
		bottom: 300px;
	}
	&.sixteenth {
		right: -80px;
		bottom: 500px;
	}
	&.seventeenth {
		right: 800px;
		top: -80px;
	}
	&.eighteenth {
		right: 1000px;
		top: -200px;
		transform: rotate(211deg);
	}
}
.content-wrapper {
	display: flex;
	flex-direction: column;
}
:deep(.cta-container) {
	z-index: $level1;
	position: relative;
}
.top-wrapper {
	width: 100vw;
	margin-bottom: 3rem;

	.title {
		position: absolute;
		top: 50px;
		width: 100%;
		text-align: center;
		z-index: 3;
	}
	@include media-breakpoint-up(md) {
		.title {
			left: 82.5px;
			width: auto;
		}
	}

	@include media-breakpoint-up(lg) {
		.title {
			top: 50px;
		}
	}
	@include media-breakpoint-max-xl() {
		margin-left: 50%;
		transform: translateX(-50%);
	}
	@include media-breakpoint-up(xl) {
		width: 100%;
		transform: none;
		margin-bottom: 0;
		.title {
			position: static;
			text-align: left;
		}
	}
}
.section {
	position: relative;
	background-color: $white;
	overflow: hidden;
	padding-bottom: 6rem;
	top: 0;
	left: 0;

	&::before {
		content: '';
		position: absolute;
		top: 1rem;
		left: 1rem;
		right: 1rem;
		bottom: 1rem;
		border: 1px solid $gold;
		z-index: 1001;
		pointer-events: none;
	}
	@include media-breakpoint-up(lg) {
		background-color: $white;
	}
	@include media-breakpoint-up(xl) {
		padding: 5rem 0;
	}
	@include media-breakpoint-up(xxl) {
		padding: 12rem 0 9rem;
	}
	&.with-margin {
		margin-top: 4rem;
		@include media-breakpoint-up(lg) {
			margin-top: 8rem;
		}
	}
}
.cta {
	&-icon {
		width: 30px;
		height: 30px;
		margin-right: 2rem;
		flex-shrink: 0;
	}
	&-content-wrapper {
		position: relative;
		display: flex;
		flex-direction: column;
		z-index: 1;
		&::before {
			content: '';
			position: absolute;
			top: -3rem;
			left: -50vw;
			margin-left: 50%;
			width: 150%;
			height: 125%;
			background-color: $gray-400;
			z-index: -1;
			@include media-breakpoint-up(md) {
				display: none;
			}
		}

		& :deep(.cta-button)  {
			order: 1;
			margin-bottom: 0;

			@include media-breakpoint-up(md) {
				order: 0;
				margin-bottom: 4rem;
				width: max-content;
			}
		}
	}
	&-description {
		margin-bottom: 3rem;

	}
	&:deep(.cta-email) {
		@include media-breakpoint-up(xl) {
			font-size: 3.5rem;
		}

	}
	&:deep(.cta-phone) {
		margin-top: 1rem;
		display: flex;
		align-items: center;
		.cta-phone-text {
			& :deep(strong)  {
				color: $secondary;
			}
		}
		@include media-breakpoint-up(lg) {
			margin: 1rem 0;
		}
		@include media-breakpoint-up(lg) {
			margin: 0;
		}
	}
	&.visible {
		@include media-breakpoint-up(xl) {
			.second {
				transform: translateX(-30px) rotate(15deg);
			}
			.third {
				transform: translateX(-30px);
			}
			.fourth {
				transform: translateX(-30px);
			}
			.fifth {
				transform: translateX(30px) rotate(95deg);
			}
			.sixth {
				transform: translateX(30px) scaleX(-1);
			}
			.seventh {
				transform: translateX(30px);
			}
			.eighth {
				transform: translateX(30px);
			}
			.ninth {
				transform: translateX(30px);
			}
			.tenth {
				transform: translateX(30px);
			}
			.eleventh {
				transform: translateX(30px);
			}
			.twelfth {
				transform: translateX(30px);
			}
			.thirteenth {
				transform: translateX(30px);
			}
			.fourteenth {
				transform: translateX(30px);
			}
			.fifteenth {
				transform: translateX(30px);
			}
			.sixteenth {
				transform: translateX(30px);
			}
			.seventeenth {
				transform: translateX(30px);
			}
			.eighteenth {
				transform: translateX(30px) rotate(211deg);
			}
		}
	}
}
</style>
